<template>
    <div style="min-height:200px">
        <b-card>
            <app-view-table :title="trans('settings-queues',207)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items"  :fields="fields" @onRowClicked="item=>$set(item, '_showDetails', !item._showDetails)" :filterOptions="status_options" :isFilterOptions="true" :isSearch="false" :isDropdownMenu="true">

                <template v-slot:row-details="row">

                    <h4 class="mb-3">{{trans('settings-data',207)}}</h4>
                    <b-row>
                        <b-col start>
                            <b-alert variant="light" show class="p-3 mb-2 app-local-height d-flex align-items-center">
                                <div class="text-small"><pre class="app-local-pre"> {{JSON.stringify(JSON.parse(row.data.item.data), null, 2)}}</pre></div>    
                            </b-alert> 
                        </b-col>
                    </b-row>

                    
                    <h4 class="mb-3" v-if="filters.list_type == 2">{{trans('settings-exception',167)}}</h4>
                    <b-row v-if="filters.list_type == 2">
                        <b-col start>
                            <b-alert variant="light" show class="p-3 mb-2 app-local-height d-flex align-items-center">
                                <div class="text-small"><pre class="app-local-pre"> {{row.data.item.exception}}</pre></div>    
                            </b-alert> 
                        </b-col>
                    </b-row>

                    
                </template>

                <template v-slot:menu-items="cell">
                        <b-dropdown-item v-if="cell.data.item.profile_id != null && cell.data.item.profil_type == 1" :to="{ name: 'people-person-profile', params: { id_person_data_main: cell.data.item.profile_id }}">{{trans('to-profile',175)}}</b-dropdown-item> 
                        <b-dropdown-item v-if="cell.data.item.profile_id != null && cell.data.item.profil_type == 2" :to="{ name: 'clients-profile-organization', params: { id_client_data_main: cell.data.item.profile_id }}">{{trans('to-profile',175)}}</b-dropdown-item> 
                        <b-dropdown-item v-if="cell.data.item.profile_id != null && cell.data.item.profil_type == 3" :to="{ name: 'planning-shifts-profile', params: { id_shift_data_main: cell.data.item.profile_id }}">{{trans('to-profile',175)}}</b-dropdown-item> 
                        <b-dropdown-item v-if="cell.data.item.profile_id != null && cell.data.item.profil_type == 4" :to="{ name: 'planning-assignments-profile', params: { id_assignment_data_main: cell.data.item.profile_id }}">{{trans('to-profile',175)}}</b-dropdown-item> 
                </template> 
            
            </app-view-table>
        </b-card>  

    </div>
</template>
<script>

import axios from "@axios";

export default {

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            loading_list: false,
            filters: {
                page: 1,
                per_page: 10,
                 sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                 filtering: {                        
                        types: {type: 'list', changed: 0, values: [], function: "getQueueType", parameters: null, widthClass:'app-width-min-250'},
                        dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'}
                },
                search: "",
                list_type:1,
                status: 1,
                changed: 0,
            },

            status_options: [
                { value: 1, name: this.trans('settings-actual',207) },
                { value: 2, name: this.trans('settings-incorrect',207) },
                { value: 3, name: this.trans('history',182) },
            ],
            fields: [
                { key: "created_date", label: this.trans('settings-event-date',207), thStyle: { "min-width": "200px", "max-width": "200px"}, visible: true, filtering:true, filtering_name: "dates" },
                { key: "type", label: this.trans('type',182), thStyle: { "min-width": "400px", "max-width": "400px" }, visible: true, filtering:true, filtering_name: "types" },
                { key: "job_class", label: this.trans('settings-class-name',207), thStyle: { "min-width": "400px", "max-width": "400px" }, visible: true, filtering:true, filtering_name: "types" },
                { key: "profile_description", label: this.trans('description',182), thStyle: { width: "100%" }, visible: true, sortable: false },
                
            ],
            items: [],
        };
    },

    methods: {
        getList: function() {
            this.loading_list= true,
            axios
                .post("settings/queue/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error : ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.list_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.types.dates', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });
        },
    },
 
};
</script>
<style scoped>
    .app-local-pre{
        white-space: pre-wrap;
    }
</style>